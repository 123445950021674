.container {
  height: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader {
  --double-size-px: calc(2 * var(--size));
  --minus-double-size-px: calc(-1 * var(--double-size-px));

  width: var(--size);
  height: var(--size);
  position: relative;

  border-radius: 50%;
  background-color: #fff;
  box-shadow: var(--double-size-px) 0 #fff, var(--minus-double-size-px) 0 #fff;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: var(--second-color);
    box-shadow: var(--double-size-px) 0 var(--second-color), var(--minus-double-size-px) 0 var(--first-color);
  }
  50% {
    background-color: var(--first-color);
    box-shadow: var(--double-size-px) 0 var(--second-color), var(--minus-double-size-px) 0 var(--second-color);
  }
  100% {
    background-color: var(--second-color);
    box-shadow: var(--double-size-px) 0 var(--first-color), var(--minus-double-size-px) 0 var(--second-color);
  }
}
