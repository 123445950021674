@keyframes fadein {
	0% {
		transform: translateX(-110px);
	}
	100% {
		transform: translateX(140px);
	}
}

.loader {
	position: relative;
	overflow: hidden;
	background-color: #82a268;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	&__bird {
		background-image: url('../../../assets/bird.svg');
		position: absolute;
		background-position: 20% 0%;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.5;
		width: 105%;
		height: 105%;
		z-index: 0;
		top: 0;
		right: 0;
	}

	&__content {
		z-index: 1;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__title {
		z-index: 1;
		width: 240px;
		margin-top: 40px;
		font-family: 'Steppe', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #ffffff;
	}

	&__footer {
		z-index: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 40px;

		&_animation {
			position: relative;
		}

		&_foreground {
			animation: 4s infinite fadein;
			position: absolute;
			transform: translateX(0%);
			width: 300px;
			height: 100%;
			background: linear-gradient(
				to right,
				rgba(130, 162, 104, 0) 0%,
				rgba(130, 162, 104, 1) 50%,
				rgba(130, 162, 104, 1) 100%
			);
		}

		&_list {
			z-index: 1;
			position: absolute;
			width: 300px;
			height: 20px;
			position: relative;
			font-family: 'Palmer Lake';
			font-style: normal;
			font-weight: 400;
			font-size: 32px;
			line-height: 60%;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.05em;
			color: #ffffff;

			> li {
				width: 100%;
				position: absolute;
				transform: translate(-50%, -50%);
				left: 50%;
				top: 50%;
			}
		}
	}
}
