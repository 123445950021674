.menu {
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
	padding-left: 16px;
	padding-right: 16px;

	&__item {
		min-width: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__icon {
		width: 24px;
		height: 24px;
	}

	&__name {
		margin-top: 8px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 140%;
		letter-spacing: 0.025em;
		color: #3b3b3c;

		&.active {
			font-weight: 500;
		}
	}
}
