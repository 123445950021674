.header {
	padding: 25px 0 36px;
	margin-top: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__logo {
		> svg {
			width: 185px;
		}
	}
}
