.modal {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: fit-content;
	z-index: 9999;
	overflow: hidden;
	background: #ffffff;
	transform: translateX(100%);
	padding: 24px 32px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px -8px 16px rgba(0, 0, 0, 0.08);
	border-radius: 8px 8px 0px 0px;

	&__title {
		margin: 0 auto 24px;
		max-width: 311px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #3b3b3c;
	}

	&__cancel {
		margin-top: 24px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 140%;
		letter-spacing: 0.05em;
		text-align: center;
		text-decoration-line: underline;
		color: #c15c3d;
	}
}
