.portal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(230, 230, 230, 0.7);
	backdrop-filter: blur(4px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 0;
	padding: 0;
	background: #ffffff;
	box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
		0px 0px 1px rgba(0, 0, 0, 0.04);

	> button {
		position: absolute;
		right: 24px;
		top: 24px;
	}
}
