.container {
	position: relative;
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	height: 100%;
}

.wrapper {
	width: 100%;
	overflow: hidden auto;
	padding: 16px;
	height: 100%;
}
