.container {
	width: 100%;
	flex: 1 1 auto;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.form {
		width: 100%;
		max-height: 550px;
		flex: 1 1 auto;
		margin-top: 48px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.recoverLink {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #98989a;
		}

		.submit {
			margin-top: 24px;
			width: 100%;
		}

		.socials {
			flex: 1 1 auto;
			margin-top: 8vh;

			&_title {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 140%;
				letter-spacing: 0.05em;
				color: #98989a;
				text-align: center;
			}
		}
	}
}
