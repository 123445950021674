.select {
	position: relative;
	background: #ffffff;
	width: 100%;
	border: 1px solid #98989a;
	border-radius: 4px;
	cursor: pointer;

	> label {
		position: absolute;
		top: -10px;
		padding: 0 4px;
		background-color: #ffffff;
		left: 12px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	&.isOpen {
		background: #fff;
		border-radius: 8px 8px 0 0;

		.arrow {
			transform: rotate(180deg);
		}
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.6;
	}

	&__header {
		padding: 13px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		> p {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #98989a;
		}

		> .arrow {
			width: 16px;
			height: 16px;
		}
	}

	&__options {
		padding: 8px 16px;
		padding: 8px 16px;
		position: absolute;
		background: white;
		width: calc(100% + 2px);
		left: -1px;
		z-index: 10;
		border-right: 1px solid #98989a;
		border-bottom: 1px solid #98989a;
		border-left: 1px solid #98989a;
		border-radius: 0 0 8px 8px;

		> li {
			padding: 8px 0;
			font-weight: 600;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #98989a;
		}
	}
}
