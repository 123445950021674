.tabs {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__actions {
		width: 100%;
		margin-top: 8vh;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 24px;

		&.signUp {
			margin-top: 48px;
		}
	}
}
