.container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 310px;
  margin-top: 16px;

  > div {
    display: flex;
    flex-direction: column;

    > p:not(:last-child) {
      margin-top: 2px;
    }
  }

  > div:not(:last-child) {
    margin-right: 28px;
  }
}

.title {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #98989a;
}

.value {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #3b3b3c;
}

.divider {
  width: 1px;
  height: 40px;
  background: #cccccc;
  border-radius: 8px;
}
