.wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;

	> .container {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	> p {
		min-height: 20px;
		padding: 4px 16px 0;

		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: #98989a;
	}

	&.error {
		> .container { &, &:has(input:focus) {
			& label {
				color: #c15c3d;
			}

			svg path {
				fill: #c15c3d;
			}
		} }

		> p.error {
			color: #c15c3d;
		}
	}
}
