.reservationsEmpty {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__title {
		margin-top: 40px;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	&__content {
		margin-top: 8px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #98989a;
	}
}
