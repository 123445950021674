.successReset {
	padding: 60px 0 32px;
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 310px;
	margin: 0 auto;
	align-items: center;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> svg {
			width: 219px;
		}
	}

	&__content {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		margin-top: 72px;
	}

	&__title {
		margin-top: 40px;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	&__info {
		margin-top: 8px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #98989a;

		> span {
			color: #3b3b3c;
		}
	}

	&__retour {
		cursor: pointer;
		margin: 16px 0;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		text-decoration-line: underline;
		color: #3b3b3c;
	}

	&__return {
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #98989a;

		> a {
			cursor: pointer;
			display: block;
			font-size: 14px;
			color: #82a268;
			text-decoration: underline;
		}
	}
}
