.bottomModal {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 19999;
	background: #ffffff;
	transform: translateY(100%);
	padding: 24px 32px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px -6px 20px 3px rgba(0,0,0,0.58);
	border-radius: 8px 8px 0px 0px;

	> .title {
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #3b3b3c;
		text-align: center;
	}

	> .content {
		margin-top: 8px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #98989a;
		text-align: center;

		& :nth-child(n) {
			margin-top: 24px;
		}
		& :first-child {
			margin-top: 0;
		}
	}
}
