.container {
	overflow: hidden;
	> div {
		height: 100%;
	}
}

.photo {
	width: 100%;
	object-fit: cover;
	display: block;

	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
