.addressLink {
	display: flex;
	font-family: 'SF Pro Display';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 140%;
	letter-spacing: 0.05em;
	text-decoration-line: underline;
	color: #98989a;

	> svg {
		margin-right: 6px;
	}

	> a {
    white-space: nowrap;
    overflow: hidden;
		text-overflow: ellipsis;
	}
}
