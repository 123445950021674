.reservations {
	margin: 0 16px;
	display: flex;
	flex-direction: column;
	height: 100%;

	&__title {
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	&__tabs {
		display: flex;
		margin-top: 24px;
		padding: 8px;
		background: #f7f7f7;
		border-radius: 4px;
	}

	&__button {
		transition: 0.3s;
		width: 100%;
		padding: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 140%;
		letter-spacing: 0.05em;
		text-transform: capitalize;
		color: #98989a;
		background: transparent;
		border-radius: 4px;

		&.active {
			color: #ffffff;
			background: #82a268;
		}
	}

	&__wrapper {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}
}
