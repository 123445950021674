.container {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 20px 32px 40px 32px;
	justify-content: space-between;

	.wrapper {
		max-width: 324px;
		margin: 0 auto;
	}

	.arrow {
		position: absolute;
		z-index: 111;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 4px;
		width: 40px;
		height: 40px;
		top: 16px;
		min-width: 40px;

		> svg {
			min-width: 40px;
		}
	}

	.title {
		display: flex;
		justify-content: center;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		display: flex;
		align-items: flex-end;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	.select {
		margin-top: 28px;
	}

	.coworking {
		background: #f7f7f7;
		border-radius: 4px;
		padding: 12px;
		margin-top: 24px;

		.image {
			min-width: 64px;
			width: 64px;
			height: 64px;
			border-radius: 4px;
			overflow: hidden;
			background-color: #3b3b3c;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.content {
			display: flex;
			padding-bottom: 16px;
			border-bottom: 1px solid #d9d9d9;
		}

		.info {
			margin-left: 10px;

			&__name {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 140%;
				letter-spacing: 0.05em;
				color: #3b3b3c;
			}

			&__address {
				margin-top: 2px;
			}

			&__total {
				display: flex;
				align-items: center;
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 140%;
				text-align: center;
				letter-spacing: 0.05em;
				color: #3b3b3c;

				&_cost {
					font-family: 'SF Pro Display';
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					color: #98989a;

					&_free {
						color: #82a268;
					}
				}

				&_divider {
					width: 1px;
					height: 20px;
					background: #cccccc;
					border-radius: 8px;
					margin: 0 6px;
				}

				&_time {
					font-weight: 400;
					font-family: 'SF Pro Display';
					font-size: 12px;
					line-height: 14px;
					color: #98989a;

					> span {
						margin-right: 2px;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 140%;
						letter-spacing: 0.05em;
						color: #3b3b3c;
					}
				}
			}
		}

		.about {
			padding-top: 12px;
			&__title {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 140%;
				letter-spacing: 0.05em;
				color: #3b3b3c;
			}

			&__address {
				margin-top: 4px;
				display: flex;
				align-items: center;
				> p {
					margin-left: 8px;
					font-family: 'SF Pro Display';
					font-style: normal;
					font-weight: 400;
					font-size: 13px;
					line-height: 140%;
					letter-spacing: 0.05em;
					text-decoration-line: underline;
					color: #98989a;
				}
			}

			&__availability {
				margin-top: 4px;
				display: flex;
				flex-direction: column;

				&__item {
					margin-top: 4px;
					display: flex;

					> p {
						margin-left: 8px;
						font-family: 'SF Pro Display';
						font-style: normal;
						font-weight: 400;
						font-size: 13px;
						line-height: 140%;
						letter-spacing: 0.025em;
						color: #3b3b3c;

						> span {
							font-weight: 600;
							color: #82a268;
						}
					}
				}
			}
		}
	}

	.divider {
		height: 1px;
		min-height: 1px;
		margin: 24px 0 24px;
		background: #cccccc;
		width: 100%;
	}

	.timepicker {
		display: flex;
		flex-direction: column;
		> h3 {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #3b3b3c;
		}

		.time {
			display: flex;
			justify-content: space-between;

			&__divider {
				height: 40px;
				width: 1px;
				background: #cccccc;
				border-radius: 8px;
			}

			&__container {
				display: flex;
				flex-direction: column;

				.title {
					margin-bottom: 2px;
					font-family: 'SF Pro Display';
					font-style: normal;
					font-weight: 400;
					font-size: 13px;
					line-height: 140%;
					letter-spacing: 0.05em;
					color: #98989a;
				}

				.date {
					font-family: 'SF Pro Display';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 140%;
					letter-spacing: 0.05em;
					color: #98989a;

					> span {
						color: #3b3b3c;
						font-weight: 600;
					}
					&.error > span {
						color: #c15c3d;
					}
				}
			}
		}

		.button {
			margin: 16px auto 0;
			padding: 10px 16px;
			width: 311px;
			height: 36px;
			background: #82a268;
			border-radius: 4px;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 16px;
			color: #ffffff;
		}

		.info {
			margin-top: 24px;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #98989a;

			&.error {
				color: #c15c3d;
			}
		}
	}

	.footer {
		display: flex;
		margin-top: 48px;
		align-items: center;
		justify-content: space-between;

		.summary {
			display: flex;
			flex-direction: column;

			&__cost {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 21px;
				display: flex;
				align-items: center;
				color: #98989a;

				&__free {
					color: #82a268;
				}
			}

			&__time {
				margin-top: 4px;
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 16px;
				color: #cccccc;

				> span {
					font-weight: 600;
					font-size: 18px;
					line-height: 21px;
				}
			}
		}

		.reserve {
			width: 162px;
		}
	}
}
