.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #3b3b3c;

  cursor: pointer;

  > input {
    -webkit-appearance: none;
    appearance: none;
  }

  > svg.checkbox {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    user-select: none;

    > path[id=unchecked] {
      fill: #98989A;
    }
    > path[id=checked] {
      fill: unset;
    }
  }

  > p:not(:first-child) {
    margin-left: 16px;
  }

  &:has(input:focus) {
    > svg.checkbox {
      > path[id=unchecked] {
        fill: #82A268
      }
    }
  }
  &:has(input:checked) {
    > svg.checkbox {
      > path[id=unchecked] {
        fill: unset;
      }
      > path[id=checked] {
        fill: #82A268;
      }
    }
  }
}
