.cancelButton {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #82a268;
  text-align: center;
  cursor: pointer;
}
