.successRegister {
	position: relative;
	background: #82a268;
	height: 100%;

	&__wrapper {
		padding: 8vh 32px 32px;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		height: 100%;
		align-items: center;
		justify-content: space-between;
	}

	&__header {
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__content {
		z-index: 1;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 14vh;
	}

	&__title {
		z-index: 1;
		margin-top: 40px;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #fff;
	}

	&__info {
		z-index: 1;
		max-width: 264px;
		margin-top: 24px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #ffffff;
	}

	&__footer {
		z-index: 1;
		color: #ffffff;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		text-decoration-line: underline;
	}

	&__bird {
		background-image: url('../../../assets/bird.svg');
		position: absolute;
		background-position: 20% 0%;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.5;
		width: 105%;
		height: 105%;
		z-index: 0;
		top: 0;
		right: 0;
	}
}
