.button {
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(130, 162, 104, 0.2);
	border-radius: 4px;
	min-width: 40px;
	height: 40px;
}
