.main {
	padding-top: 5vh;
	display: flex;
	flex-direction: column;
	height: initial;
	max-width: 310px;
	margin: 0 auto;
	align-items: center;
	height: 100%;
}

.conditions {
	margin-top: 32px;
	font-family: 'SF Pro Display';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #98989a;
	text-align: center;
}
