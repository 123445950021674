.container {
	margin: 0 16px;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.title {
	margin-bottom: 24px;
	font-family: 'Steppe';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #3b3b3c;
}

.spaces {
	position: relative;
	flex: 1;

	> :nth-child(n) {
		margin-top: 8px;
	}
	> :first-child {
		margin-top: 0;
	}
}
