.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	overflow: hidden scroll;
	background: #ffffff;
	transform: translateX(100%);
	padding: 32px 24px;
	display: flex;
	flex-direction: column;

	&__header {
		margin-bottom: 36px;
		display: flex;
		justify-content: center;
		position: relative;

		.arrow {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0, -50%);
		}
	}

	&__title {
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		display: flex;
		align-items: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	&__info {
		margin-top: 4px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: justify;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	&__content {
		font-family: 'SF Pro Display';
		font-style: normal;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #3b3b3c;
		// width: calc(100% - 48px);

		> h3 {
			margin: 24px 0;
			font-weight: 600;
			margin-bottom: 24px;
		}

		> p {
			margin: 12px 0 0 0;
			font-weight: 400;
			text-decoration: underline;
		}

		> ul {
			margin: 0;
			> li {
				list-style: disc;
				margin-left: 24px;
			}
		}

		a {
			text-decoration: underline;
		}
	}
}
