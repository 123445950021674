.layout {
	width: calc(100% + 32px);
	height: calc(100% + 16px);
	margin: -16px;
	display: flex;
	flex-direction: column;

	&__wrapper {
		flex: 1;
		overflow-y: auto;
	}
}
