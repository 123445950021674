.reservation {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	padding: 16px;
	overflow-y: scroll;
	background: #ffffff;
}

.header {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  &_empty {
    width: 40px;
  }

  > p {
    font-family: 'Steppe';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #3b3b3c;
    width: 132px;
  }
}

.dateTime {
  margin-top: 16px;
}
