.main {
	padding-top: 5vh;
	display: flex;
	flex-direction: column;
	height: initial;
	max-width: 310px;
	margin: 0 auto;
	align-items: center;
	height: max-content;
}
