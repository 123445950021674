.background {
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background: rgba(59, 59, 60, 0.4);
	z-index: 100;
	display: flex;
	justify-content: flex-end;

	.container {
		position: relative;
		margin: 60px 20px;
		height: fit-content;
		padding: 16px;
		width: 335px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.025em;
		color: #98989a;
		background: #ffffff;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
		border-radius: 8px;

		> button {
			right: 0px;
			top: 4px;
			position: absolute;
		}
	}
}
