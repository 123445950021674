.container {
  padding: 12px;
  border-radius: 4px;
  background: #f7f7f7;
}

.content {
  display: flex;
}

.image {
  min-width: 64px;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #3b3b3c;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.info {
  margin-left: 10px;

  &_name {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.05em;
    color: #3b3b3c;
  }

  &_address {
    margin-top: 2px;
  }

  &_total {
    display: flex;
    align-items: center;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #3b3b3c;

    &_cost {
      font-family: 'SF Pro Display';
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #98989a;

      &_free {
        color: #82a268;
      }
    }

    &_divider {
      width: 1px;
      height: 20px;
      background: #cccccc;
      border-radius: 8px;
      margin: 0 6px;
    }

    &_time {
      font-weight: 400;
      font-family: 'SF Pro Display';
      font-size: 12px;
      line-height: 14px;
      color: #98989a;

      > span {
        margin-right: 2px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.separator {
  padding-top: 8px;
  border-bottom: 1px solid #d9d9d9;
  position: relative;

  cursor: pointer;

  > .chevron {
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 6px);
    display: flex;

    background-color: #f7f7f7;

    > svg {
      width: 12px;
      height: 12px;
      fill: #d9d9d9;
    }
  }
}

.about {
  padding-top: 12px;

  &_title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;
    color: #3b3b3c;
  }

  &_address {
    margin-top: 4px;
    display: flex;
    align-items: center;
    > p {
      margin-left: 8px;
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      color: #98989a;
    }
  }

  &_availability {
    margin-top: 4px;
    display: flex;
    flex-direction: column;

    &_item {
      margin-top: 4px;
      display: flex;

      > p {
        margin-left: 8px;
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: 0.025em;
        color: #3b3b3c;

        > span {
          font-weight: 600;
          color: #82a268;
        }
      }
    }
  }
}
