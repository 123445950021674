.root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 1 1 auto;
	width: 100%;
}

.signUp {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&__header {
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
		margin-bottom: 32px;
	}

	&__socials {
		flex: 1 1 auto;
		margin-top: 24px;

		&_title {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #98989a;
			text-align: center;
		}
	}

	&__info {
		margin-top: -24px;
		margin-bottom: 64px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	&__input {
		width: 100%;

		&:first-of-type {
			margin-top: 20px;
		}
	}

	&__password {
		margin-bottom: 4vh;
		width: 100%;
	}

	&__forgot {
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	&__submit {
		margin-top: 32px;
		width: 100%;
	}

	&__conditions {
		margin-top: 52px;

		&_label {
			margin-left: 10px !important;

			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 140%;
			letter-spacing: 0.02em;
			color: #98989a;
		}

		&_link {
			color: #82a268;
			text-decoration: underline;
		}
	}
}
