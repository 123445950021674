.container {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	&_absolute {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	& .indicator {
		border-radius: 50%;
		box-sizing: border-box;

		width: 100%;
		max-width: var(--size);
		aspect-ratio: 1;

		border-top: 3px solid #82a268;
		border-right: 3px solid transparent;

		-webkit-animation: rotation 1s linear infinite;
		animation: rotation 1s linear infinite;

		@keyframes rotation {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		&_large {
			max-width: 48px;
		}
		&_medium {
			max-width: 24px;
		}
		&_small {
			max-width: 16px;
		}
	}

	&.contrast .indicator {
		border-color: white;
	}

	&.paused {
		-webkit-animation-play-state: paused;
		animation-play-state: paused;
	}

	&.background {
		background-color: rgba(59, 59, 60, 0.15);;
	}
}
