.root {
	position: relative;

	&__scroll {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 16px 0;
		overflow-y: scroll;
	}

	&__inner {
		position: relative;
		margin-left: 50px;
		font-size: 1.625rem;
		font-weight: bold;
		line-height: 1.167;
	}
}

.list {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.item {
	position: relative;
	width: 100%;
	background-color: white;
	border-top: 1px solid #cccccc;
	border-left: 1px solid transparent;
	height: 31px;
	flex: 0 0 31px;

	&.disabled-time + &:not(.disabled-time) {
		border-top: none;
	}

	&_last {
		position: relative;
		border-bottom: 1px solid #98989a;
	}

	&.disabled-time + &_last {
		border-bottom: none;
	}

	&__time {
		margin: 0;
		font-size: 0.625rem;
		font-weight: 400;
		line-height: 31px;
		position: absolute;
		top: -15px;
		left: -50px;
		width: 40px;
		text-align: left;
		user-select: none;
		color: #cccccc;
	}

	&__hour {
		border-top: 1px solid #98989a;
	}

	&__hour &__time {
		font-weight: 500;
		color: #98989a;
	}
}

.selection {
	border: none;
	opacity: 1;
	height: 100%;
	width: 100%;
	margin: 0;
	display: flex;
	align-items: flex-end;
	position: relative;
	padding-left: 4px;
	color: rgb(255, 255, 255);
	border-left: 4px solid #82a268;
	background: linear-gradient(
			to right bottom,
			#82a268 6.25%,
			#b6c9a7 0px,
			#b6c9a7 43.75%,
			#82a268 0px,
			#82a268 56.25%,
			#b6c9a7 0px,
			#b6c9a7 93.75%,
			#82a268 0px
		)
		0% 0% / 4.25px 4.25px;
	font-size: 13px;
	user-select: none;

	&_container {
		transition: opacity ease 0.3s;

		&_loading {
			opacity: 0;
		}
	}

	&--disabled {
		opacity: 0.7;
	}

	&__time {
		position: absolute;
		left: 6px;
		top: 10px;
		background: #82a268;
		border-radius: 4px;
		padding: 3px 8px;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.025em;
	}
}

.item.disabled-time {
	border-left: 4px solid #c15c3d;
	border-top: none;
	background: linear-gradient(
			to right bottom,
			#cccccc 6.25%,
			rgb(236, 236, 236) 0px,
			rgb(236, 236, 236) 43.75%,
			#cccccc 0px,
			#cccccc 56.25%,
			rgb(236, 236, 236) 0px,
			rgb(236, 236, 236) 93.75%,
			#cccccc 0px
		)
		0% 0% / 4.25px 4.25px;
}

.resizeHandleWrapper > div {
	background: #82a268;
	width: 24px !important;
	height: 24px !important;
	border-radius: 50%;
	left: auto !important;
	right: 10px !important;

	&::after {
		content: '';
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: url('../../assets/resizer.svg') no-repeat center center;
	}

	&:first-child {
		top: -12px !important;
	}
	&:last-child {
		bottom: -12px !important;

		&::after {
			transform: rotate(-90deg);
		}
	}
}
