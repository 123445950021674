.button {
	width: 100%;
	min-width: 103px;
	background: #ffffff;
	border: 1px solid #82a268;
	color: #98989a;
	border-radius: 4px;
	padding: 12px 16px;
	font-family: 'SF Pro Display';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	letter-spacing: 0.05em;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	text-transform: capitalize;
	transition: 0.3s;

	&:hover,
	&:active {
		border: 1px solid #82a268;
		background: #82a268;
		color: #ffffff;
	}

	&.active {
		background: #82a268;
		border: 1px solid #82a268;
		color: #ffffff;

		&:hover,
		&:active {
			background: #688552;
		}
	}
}
