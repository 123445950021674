.root {
	padding-top: 5vh;
	display: flex;
	flex-direction: column;
	height: initial;
	max-width: 310px;
	margin: 0 auto;
	align-items: center;
	height: 100%;
}

.form {
	margin-top: 58px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 550px;
	flex: 1 1 auto;

	&__header {
		margin-bottom: 32px;

		> h1 {
			font-family: 'Steppe';
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 38px;
			text-align: center;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #3b3b3c;
		}
	}

	&__info {
		margin-top: 8px;
		margin-bottom: 64px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	&__email {
		width: 100%;
	}

	&__password {
		width: 100%;
	}

	&__forgot {
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	&__submit {
		margin-top: 24px;
		width: 100%;
		flex: 1 1 auto;
		display: flex;
		align-items: flex-end;
	}

	&__socials {
		flex: 1 1 auto;
		margin-top: 8vh;

		&_title {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #98989a;
			text-align: center;
		}
	}
}

.conditions {
	margin-top: 32px;
	font-family: 'SF Pro Display';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #98989a;
	text-align: center;
}
