.coworking {
	display: flex;
	flex-direction: column;
	background: #f7f7f7;
	border-radius: 4px;
	padding: 10px 12px 12px;

	&__name {
		margin-top: 12px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #3b3b3c;
	}

	&__image {
		position: relative;
		width: 100%;
		height: 152px;

		> img {
			width: 100%;
			height: 100%;
			border-radius: 4px;
			object-fit: cover;
		}
	}

	&__goButton {
		position: absolute;
		right: 12px;
		bottom: -20px;
	}

	&__address {
		margin-top: 2px;
		letter-spacing: 0.05em;
	}

	&__info {
		margin-top: 12px;
		padding: 12px 0 0;
		border-top: 1px solid #e6e6e6;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		> li {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #3b3b3c;
			display: flex;
			align-items: center;

			&:not(:first-child) {
				margin-top: 6px;
			}

			> span {
				margin-left: 8px;
				font-weight: 600;

				&.yellow {
					color: #ffbc24;
				}

				&.green {
					color: #82a268;
				}

				&.blue {
					color: #226169;
				}
			}
		}
	}
}
