.button {
	background: #f7f7f7;
	border-radius: 4px;
	padding: 8px 16px;
	font-family: 'SF Pro Display';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 140%;
	letter-spacing: 0.05em;
	color: #3b3b3c;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	transition: 0.3s;
	width: 100%;

	&:hover,
	&:active {
		background: #f4f4f4;
	}

	&.disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	.icon {
		margin-right: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
