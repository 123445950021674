.login__buttons {
	margin-top: 8px;
	display: flex;

	> button {
		padding: 12px;
		background: #f7f7f7;
		border-radius: 4px;
		width: 96px;
		display: flex;
		justify-content: center;
		align-items: center;

		&:not(:last-child) {
			margin-right: 12px;
		}
	}

	iframe {
		width: 96px;
		height: 50px;
		background-color: #f7f7f7;

		div {
			border: 1px solid red;
		}
	}
}
