.delete {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: 84px 32px 32px;

	.info {
		margin-top: 64px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> h1 {
			margin-top: 24px;
			font-family: 'Steppe';
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 38px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #3b3b3c;
		}

		> p {
			margin-top: 8px;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 140%;
			text-align: center;
			letter-spacing: 0.05em;
			color: #98989a;
		}
	}

	.container {
		width: 100%;
		flex: 1;
		display: flex;
		align-items: center;

		.email {
			width: 100%;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		width: 100%;

		.cancel {
			display: block;
			margin-top: 32px;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			text-align: center;
			letter-spacing: 0.05em;
			text-decoration-line: underline;
			color: #82a268;
		}
	}
}
