.button {
	background: #82a268;
	border-radius: 4px;
	padding: 12px 16px;
	font-family: 'Palmer Lake';
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 60%;
	letter-spacing: 0.05em;
	color: #ffffff;
	display: flex;
	align-items: center;
	white-space: nowrap;
	width: fit-content;
	text-transform: capitalize;
	transition: 0.3s;
	width: 100%;
	display: flex;
	justify-content: center;

	&:hover,
	&:active {
		background: #688552;
	}

	&.disabled {
		background: #b6c9a7;
		pointer-events: none;
	}

	&.attention {
		background: #c15c3d;

		&:hover,
		&:active {
			background: #ab4c2f;
		}

		&.disabled {
			background: #dda492;
			pointer-events: none;
		}
	}
}
