.rules {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 310px;
  margin-top: 24px;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #98989a;
}

.timer {
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;
    color: #3b3b3c;
  }

  .counter {
    margin-top: 4px;
    font-family: 'Steppe';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #3b3b3c;

    &.isEnding {
      color: #c15c3d;
    }

    > span {
      margin-right: 4px;
      color: #98989a;
    }
  }
}

.reservationCalendar {
  margin-top: 24px;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding: 0 16px;

  .buttons {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    &_item {
      width: 49%;
    }
  }

  > p {
    margin-top: 16px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;
    color: #82a268;
    text-align: center;

    &.error {
      color: #c15c3d;
    }
  }
}
