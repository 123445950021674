.successDelete {
	position: relative;
	background: #c15c3d;
	height: 100%;
	overflow: hidden scroll;

	&__wrapper {
		max-width: 316px;
		padding: 60px 0 32px;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}

	&__header {
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> svg {
			width: 219px;
		}
	}

	&__content {
		z-index: 1;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 88px;
	}

	&__title {
		z-index: 1;
		margin-top: 24px;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #fff;
	}

	&__info {
		z-index: 1;
		margin-top: 24px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #ffffff;

		> span {
			display: block;
			margin-top: 24px;
			font-weight: 700;
		}
	}

	&__footer {
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		> p {
			margin: 40px 0;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			text-align: center;
			letter-spacing: 0.05em;
			text-decoration-line: underline;
			color: #ffffff;
		}
	}

	&__bird {
		background-image: url('../../../assets/bird_white.svg');
		position: absolute;
		background-position: 20% 0%;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.1;
		width: 105%;
		height: 100%;
		z-index: 0;
		top: 0;
		right: 0;
	}
}
