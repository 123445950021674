.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #ffffff;
	transform: translateX(100%);
	display: flex;
	flex-direction: column;
	font-family: 'SF Pro Display';

	&__wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		max-width: 700px;
		width: inherit;
		margin: 0 auto;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 40px 20px 25px;
	}

	.timeSlots {
		flex: 1;
	}

	&__info {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(130, 162, 104, 0.2);
		border-radius: 4px;
	}

	&__title {
		width: 163px;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: flex-end;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	&__footer {
		position: relative;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px -8px 16px rgba(0, 0, 0, 0.08);
		border-radius: 8px 8px 0px 0px;

		&--selected-time {
			display: flex;
			align-items: center;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.025em;
			color: #3b3b3c;
			margin: 12px 32px;

			.divider {
				height: 14px;
				width: 1px;
				background: #98989a;
				margin: 0 8px;
			}
		}

		&--info {
			display: flex;
			justify-content: space-between;
			margin: 0 32px 24px;

			& > div,
			& > button {
				flex: 1;
			}

			&__title {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				color: #82a268;
				line-height: 21px;
				display: flex;
				align-items: center;
			}

			&__summary {
				margin-top: 4px;
				font-style: normal;
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 16px;
				color: #98989a;

				> span {
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
				}
			}
		}

		.reserve {
			width: 162px;
		}
	}
}
