.failedRegister {
	padding: 8vh 32px 32px;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	align-items: center;
	max-width: 310px;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__content {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 14vh;
	}

	&__title {
		margin-top: 40px;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	&__info {
		max-width: 280px;
		margin-top: 40px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #98989a;

		> span {
			color: #3b3b3c;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #98989a;

		> a {
			cursor: pointer;
			color: #82a268;
			text-decoration: underline;
		}
	}
}
