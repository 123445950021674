.container {
	position: relative;
	background: #82a268;
	height: calc(100% + 32px);
	margin: -16px;

	.wrapper {
		margin: 0 auto;
		height: 100%;
		padding: 60px 32px 32px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.header {
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.content {
		z-index: 1;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 36px;
	}

	.title {
		max-width: 300px;
		z-index: 1;
		margin-top: 40px;
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #fff;
	}

	.info {
		z-index: 1;
		max-width: 300px;
		margin-top: 24px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #ffffff;
	}

	.footer {
		position: relative;
		z-index: 1;
		color: #ffffff;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		text-decoration-line: underline;
	}

	.background {
		background-image: url('../../assets/bird.svg');
		position: absolute;
		background-position: 20% 0%;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.5;
		width: 105%;
		height: 100%;
		z-index: 0;
		top: 0;
		right: 0;
		pointer-events: none;
	}
}
