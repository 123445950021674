.container {
	position: relative;
	width: 100%;
	height: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	.arrow {
		position: absolute;
		top: 32px;
		left: 32px;
		z-index: 111;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 4px;
		width: 40px;
		height: 40px;
		min-width: 40px;

		> svg {
			min-width: 40px;
		}

		> svg path {
			fill: #ffffff;
		}
	}

	.carousel {
		flex-shrink: 0;
	}

	.wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.content {
		flex: 1 1 auto;
		padding: 16px 20px 22px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px -8px 16px rgba(0, 0, 0, 0.08);
		border-radius: 8px 8px 0px 0px;
		background-color: #ffffff;
		margin-top: -8px;
		position: relative;
	}

	.header {
		.name {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #3b3b3c;
		}

		.address {
			margin-top: 4px;
		}

		.hours {
			margin-top: 8px;
			display: flex;
			flex-direction: column;

			&__item {
				margin-top: 4px;
				display: flex;
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 140%;
				letter-spacing: 0.025em;
				color: #3b3b3c;

				> svg {
					margin-right: 8px;
				}

				> span {
					margin-right: 4px;
					font-weight: 600;
					color: #82a268;
				}
			}
		}
	}

	.services {
		margin-top: 24px;

		> h2 {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #3b3b3c;
		}

		.chips {
			margin-top: 4px;
			display: flex;
			flex-wrap: wrap;

			> div {
				padding: 4px 8px;
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 140%;
				letter-spacing: 0.05em;
				border-radius: 4px;
				white-space: nowrap;
				margin-bottom: 4px;

				&:not(:last-child) {
					margin-right: 8px;
				}
			}

			.yellow {
				color: #ffb40a;
				background: rgba(255, 203, 86, 0.3);
			}

			.green {
				color: #688552;
				background: rgba(130, 162, 104, 0.3);
			}

			.blue {
				color: #226169;
				background: rgba(34, 97, 105, 0.3);
			}

			.red {
				color: #c15c3d;
				background: rgba(193, 92, 61, 0.3);
			}
		}
	}

	.description {
		margin-top: 28px;

		> h2 {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #3b3b3c;
		}

		> p {
			margin-top: 4px;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #98989a;
		}
	}

	.available {
		margin-top: 24px;

		> h2 {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #3b3b3c;
		}

		.spaces {
			margin-top: 8px;

			.space {
				padding: 8px;
				background: #f7f7f7;
				border-radius: 4px;
				display: flex;
				align-items: center;

				&:not(:last-child) {
					margin-bottom: 8px;
				}

				&__image {
					width: 48px;
					height: 48px;
					border-radius: 4px;
					object-fit: cover;
				}

				&__info {
					width: 226px;
					margin-left: 10px;
					align-self: center;

					&_title {
						font-family: 'SF Pro Display';
						font-style: normal;
						font-weight: 600;
						font-size: 13px;
						line-height: 16px;
						letter-spacing: 0.05em;
						color: #3b3b3c;
					}

					&_reservation {
						font-family: 'SF Pro Display';
						font-style: normal;
						font-weight: 400;
						font-size: 12px;
						line-height: 14px;
						letter-spacing: 0.05em;
						color: #98989a;

						> span {
							margin-right: 2px;
							font-weight: 400;
							font-size: 13px;
							line-height: 16px;
							color: #82a268;
						}
					}
				}

				&__select {
					margin-left: 14px;
					> button {
						padding: 6px 10px;
						font-family: 'SF Pro Display';
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						line-height: 14px;
						letter-spacing: 0.05em;
						color: #ffffff;
						background: #82a268;
						border-radius: 4px;
					}
				}
			}
		}
	}
}
