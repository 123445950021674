.fill {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.container {
	background-color: rgb(133 133 133 / 60%);

	img {
		display: none !important;
	}
}

.preview {
	@extend .fill;
	position: absolute !important;
	overflow: hidden;
	display: flex;

	video {
		flex: 1;
		object-fit: fill;
	}
}

.overlay {
	@extend .fill;
	background: radial-gradient(55.17% 55.17% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%);

	.focusContainer {
		@extend .fill;
		display: flex;
		justify-content: center;
		align-items: center;

		.focus {
			position: relative;
			width: 50%;
			aspect-ratio: 1;

			@media (orientation: landscape) {
				width: unset;
				height: 50%;
			}

			> div {
				position: absolute;
				width: 40%;
				height: 40%;
				border: 0 solid #82A268;
			}
			> div:nth-child(1) {
				top: 0;
				left: 0;
				border-width: 6px 0 0 6px;
			}
			> div:nth-child(2) {
				top: 0;
				right: 0;
				border-width: 6px 6px 0 0;
			}
			> div:nth-child(3) {
				bottom: 0;
				left: 0;
				border-width: 0 0 6px 6px;
			}
			> div:nth-child(4) {
				bottom: 0;
				right: 0;
				border-width: 0 6px 6px 0;
			}
		}
	}

	.header {
		position: absolute;
		top: 32px;
		left: 32px;
		right: 32px;
		height: 40px;

		display: flex;
		justify-content: space-between;

		.button {
			width: 40px;
			height: 40px;

			display: flex;
			align-items: center;
			justify-content: center;

			background: rgba(255, 255, 255, 0.3);
			border-radius: 4px;

			> svg {
				min-width: 40px;
			}

			> svg path {
				fill: #ffffff;
			}
		}
	}

	.screenName {
		position: absolute;
		bottom: 32px;
		left: 32px;
		right: 32px;

		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #82A268;
		word-wrap: break-word;
	}
}
