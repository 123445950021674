.order {
	display: flex;
	flex-direction: column;
	background: #f7f7f7;
	border-radius: 4px;
	padding: 10px 12px 12px;

	&.archived {
		pointer-events: none;
		* {
			color: #98989a !important;
			fill: #98989a !important;
		}
		img {
			opacity: 0.4;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 152px;

		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 4px;
		}
	}

	&__goButton {
		position: absolute;
		right: 12px;
		bottom: -20px;
		z-index: 10;
	}

	&__header {
		display: flex;
		flex-direction: column;
		padding-bottom: 12px;
		border-bottom: 1px solid #e6e6e6;
	}

	&__name {
		margin-top: 12px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #3b3b3c;
	}

	&__address {
		display: flex;
		margin-top: 2px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #98989a;
		text-decoration: underline;

		> svg {
			margin-right: 6px;
		}
	}

	&__info {
		margin-top: 12px;
		padding-bottom: 12px;
	}

	&__reservation {
		display: flex;
		align-items: center;

		&_info {
			margin-left: 6px;

			> p:first-child {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 14px;
				letter-spacing: 0.05em;
				color: #98989a;
			}

			> p:last-child {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 600;
				font-size: 13px;
				line-height: 16px;
				letter-spacing: 0.05em;
				color: #3b3b3c;
			}
		}
	}

	&__time {
		display: flex;
		margin-top: 12px;
	}

	&__divider {
		width: 1px;
		height: auto;
		background: #cccccc;
		border-radius: 8px;
		margin: 0 24px;
	}

	&__date {
		display: flex;
		align-items: center;

		&_info {
			margin-left: 8px;

			.title {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 14px;
				letter-spacing: 0.05em;
				color: #98989a;
			}

			.date {
				font-family: 'SF Pro Display';
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 16px;
				letter-spacing: 0.05em;
				color: #3b3b3c;
			}
		}
	}

	&__total {
		display: flex;
		align-items: center;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #3b3b3c;

		&_cost {
			margin-left: 8px;
			font-family: 'SF Pro Display';
			font-size: 16px;
			line-height: 19px;
			color: #98989a;

			&_free {
				color: #82a268;
			}
		}

		&_divider {
			width: 1px;
			height: 20px;
			background: #cccccc;
			border-radius: 8px;
			margin: 0 6px;
		}

		&_time {
			font-weight: 400;
			font-family: 'SF Pro Display';
			font-size: 12px;
			line-height: 14px;
			color: #98989a;

			> span {
				margin-right: 2px;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
			}
		}
	}
}

.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
}
