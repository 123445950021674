.container {
	margin-top: 10px;

	border: 1px solid #98989a;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 14px 15px;

	> label {
		position: absolute;
		top: -8px;
		left: 12px;
		padding: 0 4px;
		background-color: #ffffff;

		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	> input {
		flex: 1;

		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #3b3b3c;

		&::placeholder {
			color: #8c8c8c;
		}

		@media only screen and (hover: none) and (pointer: coarse) {
			&:focus {
				font-size: 16px;
			}
		}

	}

	svg {
		user-select: none;
	}

	&:has(input:focus) {
		border: 2px solid #82a268;
		padding: 13px 14px;

		& > label {
			top: -9px;
			left: 11px;
			color: #82a268;
		}
	}
}

.error {
	> .container { &, &:has(input:focus) {
		border-color: #c15c3d;
	} }
}
