.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 16px;
  user-select: none;

  & svg {
    cursor: pointer;
  }

  &__title {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #3B3B3C;

    & > div {
      display: inline;
    }
  }
}

.dayList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 20px 16px;
    user-select: none;

    &__day {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 48px;
        border-radius: 4px;
        padding: 6px 0 8px;
        transition: background 0.2s ease-in-out;

        &--disabled {
          color: #CCCCCC;
          pointer-events: none;
        }

        &--selected {
          background: #82A268;
          color: white;
        }

        & span + span {
          margin-top: 8px;
          font-size: 15px;
          font-weight: 600;
        }
    }
}
