.alice-carousel__dots {
	margin: 30px 3px 15px;
	position: absolute;
	bottom: 0;
	transform: translate(-50%, 0);
	left: 50%;
}

.alice-carousel__dots-item {
	transition: 0.3s;
	background: #ffffff;
	opacity: 0.5;
	border-radius: 4px !important;
	width: 10px !important;
	height: 6px !important;
}

.alice-carousel__dots-item:not(:last-child) {
	margin-right: 6px !important;
}

.alice-carousel__dots-item:not(.__custom).__active {
	background: #ffffff !important;
	width: 16px !important;
	height: 6px !important;
	opacity: 1 !important;
	border-radius: 4px;
}

.coWorking .alice-carousel__dots {
	margin: 30px 3px 22px;
	position: relative;
}
