.container {
  position: relative;
  width: 100%;

  & > .normal {
    background-color: #82A268;
    color: white;

    svg > path {
      stroke: white;
    }

    &:hover, &:active {
      background: #688552;
    }
  }

  & > .contrast {
    background-color: white;
    color: #82A268;

    svg > path {
      stroke: #82A268;
    }

    &:hover, &:active {
      background: #e6e6e6;
    }
  }
}
