.faq {
	margin: 0 16px;

	&__title {
		font-family: 'Steppe';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #3b3b3c;
	}

	&__content {
		margin-top: 4px;
		font-family: 'SF Pro Display';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.05em;
		color: #98989a;
	}

	&__email {
		margin-top: 16px;
		display: flex;
		align-items: center;
		> a {
			display: block;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			letter-spacing: 0.05em;
			color: #82a268;
		}

		.copy {
			margin-left: 8px;
		}
	}

	.accordion {
		margin-top: 16px;
		margin-bottom: 50px;
		&__item {
			margin-bottom: 12px;
			border-bottom: 1px solid #b6c9a7;
		}

		&__header_button {
			display: flex;
			align-items: center;
			position: relative;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.05em;
			color: #3b3b3c;
			padding: 12px 24px 12px 0;
			width: 100%;
			text-align: left;
			transition: 0.3s;
		}

		&__header_button:after {
			position: absolute;
			right: 0;
			display: inline-block;
			content: '';
			height: 10px;
			width: 10px;
			margin-right: 12px;
			border-bottom: 1px solid #82a268;
			border-right: 1px solid #82a268;
			transform: rotate(-45deg);
			transition: 0.3s;
		}

		&__header_button[aria-expanded='true'] {
			color: #82a268;
		}

		&__header_button[aria-expanded='true']::after,
		&__header_button[aria-selected='true']::after {
			transform: rotate(45deg);
		}

		[hidden] {
			display: none;
		}

		&__panel {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.05em;
			color: #98989a;
			padding: 8px 0;
			animation: fadein 0.35s ease-in;
		}

		@keyframes fadein {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}
	}

	&__footer {
		margin: 40px 0;
		border-top: 1px solid #cccccc;
		padding-top: 40px;
		.info {
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			letter-spacing: 0.05em;
			text-decoration-line: underline;
			color: #82a268;

			&:first-child {
				margin-bottom: 16px;
			}
		}

		.logout {
			margin-top: 32px;
			font-family: 'SF Pro Display';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			letter-spacing: 0.05em;
			text-decoration-line: underline;
			color: #c15c3d;
		}
	}
}
