.container {
	width: 100%;
	flex: 1 1 auto;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.form {
		width: 100%;
		max-height: 550px;
		flex: 1 1 auto;
		margin-top: 48px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.header {
			margin-bottom: 32px;

			> h1 {
				font-family: 'Steppe';
				font-style: normal;
				font-weight: 700;
				font-size: 32px;
				line-height: 38px;
				text-align: center;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: #3b3b3c;
			}
		}

		.email {
			margin-bottom: 24px;
		}

		.submit {
			margin-top: 24px;
			width: 100%;
		}
	}
}
